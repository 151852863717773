<template>
	<main>
		<div class="main">
			<h1 class="title">CASES</h1>
		</div>
		<div class="s2">
			<div class="center">
				<div class="title-box">
					<div class="title">作品案例</div>
				</div>
				<div class="content">
					<div class="category">
						<div class="cen">
							<div class="left item" :class="[!type ? 'active' : '']" @click="chooseType({id:0})">全部案例</div>
							<div class="left item" :class="[type === item.id ? 'active' : '']" v-for="(item,index) in type_list" :key="index" @click="chooseType(item)">{{item.title}}</div>
						</div>
					</div>
					<div class="list">
						<div class="box active">
							<a-link :to="'/cases/detail?cases_id='+item.id" class="left block item" v-for="(item,index) in cases" :key="index">
								<div class="wrap">
									<div class="image">
										<img :src="item.image" :alt="item.title"/>
									</div>
									<div class="info">
										<div class="title">{{ item.title }}</div>
										<div class="tag"><span>{{ item.brand }}</span><template v-if="item.tag"><span v-for="(v,i) in tagText(item.tag)" :key="i">{{ v }}</span></template></div>
									</div>
								</div>
							</a-link>
						</div>
						<a-empty class="empty-box" :status="cases_status" @click="getCasesList"/>
					</div>
				</div>
			</div>
		</div>
		<components-contact/>
	</main>
</template>

<script>
import ComponentsContact from "@/components/views/index/contact.vue"
import {site_config} from "@/config";
import {cases} from "@/api/views";
export default {
	components:{ComponentsContact},
	data() {
		return {
			query:{},
			type_list:site_config.case_type,
			type:0,
			page:1,
			limit:6,
			cases:[],
			cases_status:0,
		}
	},
	created() {
		this.query = this.$route.query;
	},
	mounted() {
		this.type = Number( this.query.type) || 0;
		this.getCasesList();
	},
	methods: {
		tagText(tag){
			return tag ? tag.split(',') : [];
		},
		chooseType(item){
			this.type = item.id;
			this.cases_status = 0;
			this.page = 1;
			this.cases =[];
			this.getCasesList();
		},
		getCasesList() {
			this.cases_status = 0;
			cases.all({
				type:this.type,
				page:this.page,
				limit:this.limit
			}).then((res)=>{
				this.cases = this.cases.concat(res.list);
				if(this.cases.length <= 0){
					this.cases_status = 3;
				}else if(this.cases.length >= res.count){
					this.cases_status = 2;
				}else{
					this.cases_status = 1;
					this.page = this.page+1;
				}
			})
		}
	},
	async asyncData({app,query}){
		if(!query.type){
			query.type = "";
		}
		if(!query.page){
			query.page = 1;
		}
		var cases_type = await app.$api.getCasesType();
		var cases_list = await app.$api.getCasesListByPage({
			limit:6,
			page:query.page,
			type:query.type,
		});
		return {
			cases_type:cases_type.type,
			cases_list:cases_list.list,
			cases_total:cases_list.total,
			query
		}
	},
}
</script>

<style lang="scss" scoped>
.main{text-align: center;
	.title{font-size: 60px;font-weight: bold;line-height: 1; color:$main;padding:170px 0 100px;}
	.min-title{font-size: 40px;color:$main;}
}
.s2{
	.center{margin: 0 auto;
		.title-box{text-align: center;
			.title{font-size: 36px;line-height: 1; display: inline-block;position: relative;}
			.title::after{content: "";display: block;position: absolute; bottom:2px;width: 100%;height:4px;background: $main;z-index: 0;opacity: .6}
		}
		.content{
			.category{text-align: center;margin-top: 30px;
				.cen{display: inline-block;
					.item{padding: 0 0 0 15px;cursor: pointer;transition: all .3s ease;line-height: 1;color:#333;}
					.item.active{color:$main;transition: all .3s ease;}
					@media (any-hover: hover) {
						.item:hover{color:$main;transition: all .3s ease;}
					}
					.item::after{content: "/";display: inline-block;margin-left: 15px;}
					.item:nth-last-child(1)::after{display: none;}
				}
			}
			.list{margin-top: 35px;
				.box{display: none;flex-wrap: wrap;
					.item{width: 33.33%;padding: 15px;
						.wrap{background: #efefef;position: relative;cursor: pointer;overflow: hidden;
							.image{
								img{width: 100%;height:100%;object-fit: cover;object-position: top center;display: block;}
							}
							.info{position: absolute;bottom:0;left:0;padding: 30px;width: 100%;color:#fff;z-index: 2;transform: translateY(100%);transition: all .5s ease;
								.title{font-size: 26px;}
								.tag{margin-top: 10px;
									span{padding: 0 0 0 10px;opacity: .8;}
									span::after{content:"|";display:inline-block;margin-left: 10px;}
									span:nth-child(1){padding-left: 0;}
									span:nth-last-child(1)::after{display:none;}
								}
							}
						}
						.wrap::after{content:"";display: block;position: absolute;top:0;left:0;width: 100%;height:100%;background:$main;opacity:0;transition: all .5s ease;}
						@media (any-hover: hover) {
							.wrap:hover{
								.info{transform: translateY(0);transition: all .5s ease;}
							}
							.wrap:hover::after{opacity: .8;transition: all .5s ease;}
						}
					}
				}
				.box.active{display: flex;}
			}
			.empty-box{margin: 30px 0 50px;text-align: center;}
		}
	}
}
@media screen and (max-width: 1220px) {
	.main{
		.title{font-size: m(60px);padding:m(170px) 0 m(100px);}
		.min-title{font-size: m(40px);}
	}
	.s2{padding: m(80px) 0 0;
		.center{width:100%;padding: 0 25px;
			.title-box{
				.title{font-size: 30px;}
			}
			.content{
				.category{margin-top:m( 30px);
					.cen{
						.item{font-size: 14px; padding: 0 0 0 m(15px);}
						.item::after{margin-left:m(15px);}
					}
				}
				.list{margin-top: m(35px);
					.box{
						.item{padding:m( 15px);width: 50%;
							.wrap{
								.info{padding:m(20px);
									.title{font-size: 20px;}
									.tag{margin-top:m(10px);font-size: 14px;
										span{padding: 0 0 0 m(10px);}
										span::after{margin-left:m(10px);}
									}
								}
							}
							@media (any-hover: none) {
								.wrap{
									.info{transform: translateY(0);background:rgba(0,0,0,.6);}
								}
							}
						}
					}
				}
				.empty-box{margin-top: m(30px);margin-bottom: m(50px);}
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.main{
		.title{font-size: x(60px);padding:x(250px) 0 x(80px);}
		.min-title{font-size: x(40px);}
	}
	.s2{padding: x(80px) 0 ;
		.center{width:100%;padding: 0 15px;
			.title-box{
				.title{font-size: 24px;}
			}
			.content{
				.category{margin-top:m( 30px);
					.cen{
						.item{font-size: 14px; padding: 0 0 0 m(15px);line-height:30px;}
						.item::after{margin-left:m(15px);}
					}
				}
				.list{margin-top: m(35px);
					.box{
						.item{padding:m( 5px);width: 100%;
							.wrap{
								.info{padding:x(20px);
									.title{font-size: 14px}
									.tag{font-size: 12px;}
								}
							}
						}
					}
				}
				.empty-box{margin-top:x(50px);margin-bottom:x(50px);}
			}
		}
	}
}
</style>

